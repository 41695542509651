import classNames from "classnames";
import Modal from "../components/Modal";
import ModalHeader from "../components/ModalHeader";
import IOSARViewerButton from "../components/IOSARViewerButton";
import AndroidARViewerButton from "../components/AndroidARViewerButton";
import { useEffect, useState } from "react";
import { getGlbModelByName, getUsdModelByName } from "../../util/storage";

const ConfigurationXRSection = ({ onDone, activeModelId }) => {
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const [model, setModel] = useState("");
  const [loading, setLoading] = useState(false);

  // Get models by activeModelId
  useEffect(() => {
    setLoading(true);
    (isIOS || isAndroid) &&
      getModels(activeModelId)
        .then((model) => setModel(model))
        .finally(() => setLoading(false));
  }, [activeModelId, isIOS, isAndroid]);

  return (
    <Modal onDone={onDone}>
      <ModalHeader
        title={
          !isIOS && !isAndroid
            ? "Mobil cihazlarda çalışır"
            : "Modeli Görüntüleyin!"
        }
        description={
          !isIOS && !isAndroid
            ? "Bu özelliği kullanabilmek için Iphone ve Android cihazınızı kullanın."
            : "Banyonuzda görüntülemek için aşağıdaki bağlantıya tıklayın"
        }
      />
      <div
        className={classNames(
          "",
          "flex flex-col items-start justify-between",
          "gap-12 mt-6 text-left",
        )}
      >
        {!isIOS && !isAndroid ? (
          <button
            onClick={onDone}
            className={classNames(
              "",
              "font-normal",
              "bg-neutral-300 text-neutral-900",
              "hover:bg-neutral-300",
              "hover:scale-[0.99]",
              "active:scale-[0.98]",
              "px-3.5 py-3.5 rounded-xl",
              "w-full inline-flex justify-center items-center",
              "duration-200",
            )}
          >
            Tamam
          </button>
        ) : (
          <>
            {isIOS && (
              <IOSARViewerButton
                URL={model}
                text={loading ? "Yükleniyor" : "Görüntüleyin"}
              />
            )}
            {isAndroid && (
              <AndroidARViewerButton
                URL={model}
                text={loading ? "Yükleniyor" : "Görüntüleyin"}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

// Get models by device type then return model path
export async function getModels(activeModelId) {
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  try {
    return isIOS
      ? await getUsdModelByName(activeModelId)
      : await getGlbModelByName(activeModelId);
  } catch (e) {
    console.log("Error fetching model:", e);
  }
}

export default ConfigurationXRSection;
