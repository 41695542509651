import React from "react";
import { useRouter } from "../util/router";
import ConfiguratorSection from "./sections/ConfiguratorSection";

const ConfiguratorPage = () => {
  const router = useRouter();

  // Manage sections
  const validSections = {
    cover: ConfiguratorSection,
  };

  // Get the component based on the router parameter or default to the first component
  const ActiveSection =
    validSections[router.params.section] || validSections.cover;

  return <ConfiguratorSection />;
};

export default ConfiguratorPage;
