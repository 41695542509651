import React from "react";
import Meta from "./../components/Meta";
import ContentSection2 from "./../components/ContentSection2";
import ContentSection from "./../components/ContentSection";

function ContentPage(props) {
  return (
    <>
      <Meta title="Content" />
      <ContentSection2
        title="Latest Posts"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <ContentSection
        title="Latest Posts"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default ContentPage;
