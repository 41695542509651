import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query {
    products(first: 20) {
    edges {
      node {
        title
        handle
        tags
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        images(first: 1) {
          edges {
            node {
              url
              altText
            }
          }
        }
      }
    }
  }
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  query ($handle: String!) {
  productByHandle(handle: $handle) {
    title
    description
    updatedAt
    tags
    priceRange {
      minVariantPrice {
        amount
      }
    }
    images(first: 1) {
      edges {
        node {
          transformedSrc
          altText
        }
      }
    }
    variants(first:1){
      edges{
        node{
          id
        }
      }
    }
  }
}
`;

export const CHECKOUT_PRODUCT = gql`
mutation ($variantId: ID!){
  checkoutCreate(input:{
    lineItems:{
      variantId: $variantId
      quantity: 1
    }
  }){
    checkout{
      webUrl
    }
  }
}
`