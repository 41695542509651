import classNames from "classnames";
import { state } from "../util/store";
import { CubeTransparentIcon } from "@heroicons/react/24/solid";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useSnapshot } from "valtio";

const ConfiguratorFooter = ({ setXRModalIsOpen, setOrderModalIsOpen }) => {
  const snap = useSnapshot(state);

  return (
    <footer
      className={classNames(
        "",
        "fixed bottom-0 md:bottom-4 z-10 w-full py-4 ",
        "flex flex-col gap-4 ",
      )}
    >
      <div
        className={"flex px-4 md:px-0 flex-col gap-2 w-full max-w-3xl mx-auto"}
      >
        <h2
          className={classNames("", "font-normal text-6xl", "tracking-tight")}
        >
          {state.model.name}
        </h2>
        <h3 className={"text-lg text-neutral-400 font-normal"}>
          Tezgah üzeri lavabo
        </h3>
      </div>

      <div className={"w-full  overflow-x-auto"}>
        <ul className="flex min-w-fit max-w-3xl m-auto flex-row gap-2 justify-start items-center px-6 md:px-0">
          {snap.materials.map((material, index) => (
            <li key={index}>
              <button
                style={{ backgroundColor: material.color }}
                className="h-12 w-12  rounded-lg bg-zinc-700"
                onClick={() => (state.material = material)}
              ></button>
            </li>
          ))}
        </ul>
      </div>
      <div className={"px-4 drop-shadow-2xl  flex items-center justify-center"}>
        <div
          className={classNames(
            "bg-neutral-200 h-16  w-full max-w-3xl ",
            "flex flex-row items-center rounded-xl overflow-hidden justify-between",
          )}
        >
          <button
            onClick={setXRModalIsOpen}
            className={classNames(
              "",

              "bg-neutral-100 flex",
              "font-semibold tracking-tight w-full",
              "items-center justify-center min-w-fit px-4 h-full",
            )}
          >
            <CubeTransparentIcon className={"h-7 w-7 mr-3"} strokeWidth={1.0} />
            Banyonda gör
          </button>

          <button
            onClick={setOrderModalIsOpen}
            className={classNames(
              "bg-neutral-900 text-neutral-100 flex",
              "font-semibold tracking-tight w-full",
              "items-center justify-center min-w-fit px-4 h-full",
            )}
          >
            <ShoppingBagIcon className={"h-7 w-7 mr-3"} strokeWidth={1.0} />{" "}
            Sipariş Oluştur
          </button>
        </div>
      </div>
    </footer>
  );
};

export default ConfiguratorFooter;
