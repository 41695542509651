import React from "react";
import Meta from "./../components/Meta";
import FaqSection2 from "./../components/FaqSection2";
import FaqSection from "./../components/FaqSection";

function FaqPage(props) {
  return (
    <>
      <Meta title="Faq" />
      <FaqSection2
        title="Frequently Asked Questions"
        subtitle=""
        strapline="We answer"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        showSupportButton={true}
        supportUrl="https://zendesk.com"
      />
      <FaqSection
        title="Frequently Asked Questions"
        subtitle=""
        strapline="We answer"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        showSupportButton={true}
        supportUrl="https://zendesk.com"
      />
    </>
  );
}

export default FaqPage;
