import React from "react";
import Meta from "./../components/Meta";
import Navbar3 from "./../components/Navbar3";
import Navbar4 from "./../components/Navbar4";

function NavbarLandingPage(props) {
  return (
    <>
      <Meta title="Navbar Landing" />
      <Navbar3 bgColor="bg-white" />
      <Navbar4 bgColor="bg-white" />
    </>
  );
}

export default NavbarLandingPage;
