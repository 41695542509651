import React from "react";
import Meta from "./../components/Meta";
import Navbar from "./../components/Navbar";
import Navbar2 from "./../components/Navbar2";

function NavbarPage(props) {
  return (
    <>
      <Meta title="Navbar" />
      <Navbar bgColor="bg-white" />
      <Navbar2 bgColor="bg-white" />
    </>
  );
}

export default NavbarPage;
