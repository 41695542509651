import React from "react";
import Meta from "./../components/Meta";
import PricingSection3 from "./../components/PricingSection3";
import PricingSection4 from "./../components/PricingSection4";

function PricingLandingPage(props) {
  return (
    <>
      <Meta title="Pricing Landing" />
      <PricingSection3
        title="Choose the best plan"
        subtitle="Get to know our premium solutions and get your start up off the ground in no time. Inspiring results from the first days."
        strapline="Pricing"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <PricingSection4
        title="Honest and affordable pricing"
        subtitle="Get started easily and trouble free. Cancel anytime you want without hidden fees."
        strapline="Get it today"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default PricingLandingPage;
