import classNames from "classnames";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

const IOSARViewerButton = ({ URL, text }) => {
  return (
    <a
      rel="ar"
      href={URL}
      className={classNames(
        "",
        "z-40",
        "font-normal",
        "bg-neutral-900 text-neutral-100",
        "px-3.5 py-3.5 rounded-xl",
        "w-full inline-flex justify-between items-center",
      )}
    >
      <img
        src={"/assets/brand/icon-white.svg"}
        alt="product"
        className={"sr-only"}
      />
      {text || "Görüntüleyin"}
      <ArrowTopRightOnSquareIcon className="h-5 w-5 " strokeWidth={0.9} />
    </a>
  );
};

export default IOSARViewerButton;
