import { proxy } from "valtio";

const state = proxy({
  intro: true,
  models: [
    {
      id: "decente",
      name: "Decente",
      subtraction: false,
      type: "stand",
    },
    {
      id: "diamente",
      name: "Diamente",
      subtraction: false,
      type: "stand",
    },
    {
      id: "lucente",
      name: "Lucente",
      subtraction: false,
      type: "stand",
    },
    {
      id: "square",
      name: "Square",
      subtraction: false,
      type: "stand",
    },
  ],
  materials: [
    {
      id: "bright_white",
      name: "Bright White",
      metalness: 0,
      roughness: 0.1,
      color: "#dadada",
    },
    {
      id: "matte_white",
      name: "Matte White",
      metalness: 0,
      roughness: 0.4,
      color: "#dadada",
    },
    {
      id: "matte_iceblue",
      name: "Matte Iceblue",
      metalness: 0,
      roughness: 0.4,

      color: "#adb8e1",
    },
    {
      id: "bright_black",
      name: "",
      metalness: 0,
      roughness: 0.1,
      color: "#252525",
    },
    {
      id: "matte_black",
      name: "Matte Black",
      metalness: 0,
      roughness: 0.4,
      color: "#252526",
    },

    {
      id: "matte_antrachite",
      name: "Matte Antrachite",
      metalness: 0,
      roughness: 0.4,

      color: "#4B4B4B",
    },
    {
      id: "matte_latte",
      name: "Matte Latte",
      metalness: 0,
      roughness: 0.4,
      color: "#727272",
    },
    {
      id: "matte_silvergrey",
      name: "Matte Silvergrey",
      metalness: 0,
      roughness: 0.4,
      color: "#8D8D8D",
    },

    {
      id: "matte_orange",
      name: "Matte Orange",
      metalness: 0,
      roughness: 0.4,

      color: "#e8812c",
    },
    {
      id: "matte_mustardyellow",
      name: "Matte Mustardyellow",
      metalness: 0,
      roughness: 0.4,
      color: "#f1a92b",
    },

    {
      id: "matte_green",
      name: "Matte Green",
      metalness: 0,
      roughness: 0.4,

      color: "#566B56",
    },
    {
      id: "matte_petrolblue",
      name: "Matte Petrolblue",
      metalness: 0,
      roughness: 0.4,
      color: "#2f4967",
    },
  ],
  model: {
    id: "decente",
    name: "Decente",
    subtraction: false,
    type: "stand",
  },
  material: {
    id: "bright_black",
    name: "Bright Black",
    metalness: 0,
    roughness: 0.1,
    color: "#252525",
  },
  colors: ["#ccc", "#EFBD4E", "#80C670", "#726DE8", "#EF674E", "#353934"],
  decals: ["react", "three2", "pmndrs"],
  color: "#EFBD4E",
  decal: "three2",
});

export { state };
