import React from "react";
import {
  CheckCircleIcon,
  BookmarkIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";

function PricingSection4(props) {
  const perksColumns = [
    [
      <>
        <strong>300+</strong> UI Components
      </>,
      <>
        <strong>100GB</strong> SSD Storage
      </>,
    ],
    [
      <>
        <strong>Builder</strong> Access
      </>,
      <>
        <strong>200</strong> Paying Clients
      </>,
    ],
    [
      <>
        <strong>Custom</strong> Editors
      </>,
      <>
        <strong>24/7</strong> Email Support
      </>,
    ],
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-10 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="rounded-lg bg-white shadow-lg xl:mx-24">
          <div className="md:flex items-center rounded-lg ring-1 ring-black ring-opacity-5">
            <div className="grow p-5 lg:p-6">
              <h4 className="text-xl font-bold mb-1">
                300+ Premium UI Components
              </h4>
              <p className="leading-relaxed text-gray-500 mb-5">
                Get access to all available components and build amazing
                applications.
              </p>
              <h5 className="flex items-center my-8">
                <span className="text-sm uppercase tracking-wide text-blue-600 font-semibold mr-3">
                  Best Features Included
                </span>
                <span
                  className="grow bg-blue-50 rounded h-0.5"
                  aria-hidden={true}
                />
              </h5>
              <div className="lg:flex lg:space-x-12 space-y-4 lg:space-y-0 font-medium">
                {perksColumns.map((perks, index) => (
                  <ul className="space-y-4 text-sm" key={index}>
                    {perks.map((perk, index) => (
                      <li className="flex items-center space-x-2" key={index}>
                        <CheckCircleIcon className="text-emerald-500 inline-block w-5 h-5" />
                        <span>{perk}</span>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
            <div className="flex-none relative md:w-72 p-5">
              <div className="absolute mt-5 mr-5 top-0 right-0 h-10 w-10 flex items-center justify-center">
                <BookmarkIcon className="inline-block w-6 h-6 text-orange-400" />
              </div>
              <div className="p-5 lg:p-6 text-center rounded-lg bg-blue-50">
                <span className="inline-flex space-x-1 items-center text-xs uppercase tracking-wider font-semibold px-3 py-1 bg-blue-200 bg-opacity-50 text-blue-600 rounded-full mb-4">
                  One Time Payment
                </span>
                <div className="text-3xl lg:text-4xl font-extrabold mb-1">
                  $549
                </div>
                <p className="text-gray-600 text-sm font-medium mb-5">
                  Lifetime access
                </p>
                <Button
                  href={`https://app.mysite.com/purchase/pro`}
                  size="lg"
                  variant="primary"
                  startIcon={
                    <ShoppingCartIcon className="opacity-50 inline-block w-5 h-5" />
                  }
                  isBlock={true}
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default PricingSection4;
