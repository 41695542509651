import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSnapshot } from "valtio";
import { state } from "../util/store";
import Modal from "../components/Modal";
import ModalHeader from "../components/ModalHeader";

const ConfigurationOrderFormModal = ({ onDone }) => {
  const snap = useSnapshot(state);
  const [productImage, setProductImage] = useState("");

  useEffect(() => {
    const canvas = document.querySelector("canvas");

    if (canvas) {
      const image = new Image();
      image.src = canvas.toDataURL("image/png");
      setProductImage(image);
    }
  }, []);

  return (
    <Modal onDone={onDone}>
      <div className="flex flex-col justify-center md:justify-start mt-4 items-center">
        <div className="h-48 w-48 overflow-hidden bg-neutral-100 rounded-lg shadow-2xl">
          <img
            src={productImage?.src}
            alt="Product"
            className="object-cover scale-[0.95] mt-5 md:mt-8 md:scale-[1.5] h-full w-full"
          />
        </div>
        <div className="p-4 flex w-full flex-col gap-4 justify-center items-center">
          <ModalHeader
            title={snap.model.name}
            description={snap.material.name}
          />
          <div>
            <span className="text-lg mr-2">Adet</span>
            <select
              name=""
              id=""
              className="border-0 rounded-xl focus:ring-neutral-300 focus:ring-2 focus:bg-neutral-100"
            >
              {Array.from({ length: 10 }, (_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
          <input
            type="text"
            placeholder="Adınız"
            className="border-0 focus:ring-neutral-300 focus:ring-2 rounded-xl py-3 w-full md:w-3/4"
          />
          <input
            type="text"
            inputMode={"numeric"}
            placeholder="Telefon Numaranız"
            className="border-0 focus:ring-neutral-300 focus:ring-2 rounded-xl py-3 w-full md:w-3/4"
          />
          <input
            type="email"
            placeholder="E-posta adresiniz"
            className="border-0 focus:ring-neutral-300 focus:ring-2 rounded-xl  py-3 w-full md:w-3/4"
          />
          <button
            onClick={() => {
              alert("🎉 Sipariş Talebiniz Gönderilmiştir!");
              onDone();
            }}
            className={classNames(
              "",

              "w-full md:w-3/4",
              "font-normal",
              "bg-neutral-900 text-neutral-100",
              "hover:bg-neutral-300",
              "hover:scale-[0.99]",
              "active:scale-[0.98]",
              "px-3.5 py-3.5 rounded-xl",
              "w-full inline-flex justify-center items-center",
              "duration-200",
            )}
          >
            Sipariş Talebini Gönder
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigurationOrderFormModal;
