import React from "react";
import Meta from "./../components/Meta";
import Footer2 from "./../components/Footer2";
import Footer from "./../components/Footer";

function FooterPage(props) {
  return (
    <>
      <Meta title="Footer" />
      <Footer2
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        sticky={false}
      />
      <Footer
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        sticky={false}
      />
    </>
  );
}

export default FooterPage;
