import React, {useEffect} from "react";
import {useRouter} from "../util/router";

function IndexPage(props) {
    const router = useRouter();
    useEffect(() =>{
        router.push("/configurator/cover")
    }, [])
  return (
    <div
      style={{
        padding: "50px",
        width: "100%",
        textAlign: "center",
      }}
    >
      <strong>Welcome to the Divjoy component library for Tailwind</strong>
      <br />
      <br />
      Select a component type in the dropdown above
    </div>
  );
}

export default IndexPage;
