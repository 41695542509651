import React from "react";
import Meta from "./../components/Meta";
import TestimonialsSection2 from "./../components/TestimonialsSection2";
import TestimonialsSection from "./../components/TestimonialsSection";

function TestimonialsPage(props) {
  return (
    <>
      <Meta title="Testimonials" />
      <TestimonialsSection2
        title="Customer Testimonials"
        subtitle=""
        strapline="Real Feedback"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <TestimonialsSection
        title=""
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default TestimonialsPage;
