import React from "react";
import { StarIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Avatar from "./Avatar";

function TestimonialsSection(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="border rounded-md bg-gray-50 shadow-sm relative">
          <div className="absolute top-0 right-0 text-9xl mt-3 mr-5 text-blue-200 opacity-75 font-serif">
            “
          </div>
          <div className="px-6 pt-16 pb-6 md:px-12 md:pb-10 relative text-center">
            <div className="mb-3">
              <div className="inline-flex items-center text-yellow-500 bg-orange-100 px-2 py-1 rounded-lg">
                <StarIcon className="inline-block w-5 h-5" />
                <StarIcon className="inline-block w-5 h-5" />
                <StarIcon className="inline-block w-5 h-5" />
                <StarIcon className="inline-block w-5 h-5" />
                <StarIcon className="inline-block w-5 h-5" />
              </div>
            </div>
            <blockquote className="lg:w-2/3 xl:w-1/2 mx-auto">
              <p className="font-semibold text-xl leading-8 mb-5">
                I've been using Divjoy templates for years. The code is
                consistently high-quality and pleasant to work with, so I highly
                recommended them.
              </p>
              <footer className="space-y-4">
                <Avatar
                  image="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?crop=entropy&fit=crop&h=160&w=160"
                  size="md"
                  className="mx-auto"
                />
                <div>
                  <span className="text-lg font-semibold text-blue-600">
                    John Smith
                  </span>
                  <div className="mx-auto w-10 h-1 my-1 rounded-lg bg-blue-200" />
                  <p className="text-gray-500 font-medium">
                    Founder and Lead Developer
                  </p>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default TestimonialsSection;
