import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function TeamBiosSection(props) {
  const teamBios = [
    {
      avatar:
        "https://images.unsplash.com/photo-1549351236-caca0f174515?crop=entropy&fit=crop&h=320&q=80&w=320",
      name: "Irma Norton",
      role: "Founder & CEO",
    },
    {
      avatar:
        "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?crop=entropy&fit=crop&h=320&q=80&w=320",
      name: "Alejandro Lee",
      role: "Product Design",
    },
    {
      avatar:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?crop=entropy&fit=crop&h=320&q=80&w=320",
      name: "Elsa King",
      role: "Web Developer",
    },
    {
      avatar:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?crop=entropy&fit=crop&h=320&q=80&w=320",
      name: "Alex Saunders",
      role: "Marketing",
    },
    {
      avatar:
        "https://images.unsplash.com/photo-1567186937675-a5131c8a89ea?crop=entropy&fit=crop&h=320&q=80&w=320",
      name: "Herman Reese",
      role: "Support Specialist",
    },
    {
      avatar:
        "https://images.unsplash.com/photo-1514846326710-096e4a8035e0?crop=entropy&fit=crop&h=320&q=80&w=320",
      name: "Sue Keller",
      role: "Web Developer",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-16 text-center">
          {teamBios.map((bio, index) => (
            <div key={index}>
              <span className="inline-block rounded-full bg-white shadow-lg p-2 mb-5">
                <img
                  className="inline-block w-28 h-28 rounded-full"
                  src={bio.avatar}
                  alt="User avatar"
                />
              </span>
              <h4 className="text-xl font-semibold mb-1">{bio.name}</h4>
              <p className="text-gray-600 font-medium">{bio.role}</p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default TeamBiosSection;
