import ConfiguratorScene from "../components/ConfiguratorScene";
import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { DECENTE } from "../models/3040_DECENTE";
import { DIAMENTE } from "../models/3042_DIAMENTE";
import { LUCENTE } from "../models/3043_LUCENTE";
import { SQUARE } from "../models/3050_SQUARE";
import { state } from "../util/store";
import ConfigurationXRSection from "./ConfigurationXRSection";
import ConfiguratorHeader from "../components/ConfiguratorHeader";
import ConfiguratorFooter from "../components/ConfiguratorFooter";
import ConfigurationOrderFormModal from "./ConfigurationOrderFormModal";

const ConfiguratorSection = ({ props }) => {
  const snap = useSnapshot(state);
  const [XRModalIsOpen, setXRModalIsOpen] = useState(false);
  const [orderModalIsOpen, setOrderModalIsOpen] = useState(false);

  useEffect(() => {
    // Add this effect to disable scroll when the component mounts
    const body = document.body;
    const html = document.documentElement;

    // Save the current scroll position
    const scrollY = window.scrollY;

    // Prevent scrolling on both body and html
    body.style.overflow = "hidden";
    html.style.overflow = "hidden";

    // Restore the scroll position when the component unmounts
    return () => {
      body.style.overflow = "";
      html.style.overflow = "";

      // Scroll to the previous position
      window.scrollTo(0, scrollY);
    };
  }, []);

  // Manage sections
  const validModels = {
    decente: DECENTE,
    diamente: DIAMENTE,
    lucente: LUCENTE,
    square: SQUARE,
  };

  const ActiveModel = validModels[snap.model.id];

  return (
    <section className={"fixed w-screen h-screen "}>
      {XRModalIsOpen && (
        <ConfigurationXRSection
          onDone={() => setXRModalIsOpen(false)}
          activeModelId={`${snap.model.id}_${snap.material.id}`}
        />
      )}
      {orderModalIsOpen && (
        <ConfigurationOrderFormModal
          onDone={() => setOrderModalIsOpen(false)}
        />
      )}
      <ConfiguratorHeader />
      <ConfiguratorScene>
        <ActiveModel />
      </ConfiguratorScene>
      <ConfiguratorFooter
        setXRModalIsOpen={() => setXRModalIsOpen(true)}
        setOrderModalIsOpen={() => setOrderModalIsOpen(true)}
      />
    </section>
  );
};

export default ConfiguratorSection;
