import { firebaseApp } from "./firebase";
import {
  getDownloadURL,
  getStorage,
  ref,
} from "firebase/storage";

// Initialize Firebase Storage
const storageBucket = getStorage(firebaseApp);

export async function getUsdModelByName(name) {
  try {
    // Create a reference to the file you want to get the download URL for
    const storageRef = ref(storageBucket, `lucco/${name}.usdz`);

    // Get the download URL for the file
    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  } catch (error) {
    console.error("Error getting download URL:", error);
    return null;
  }
}

export async function getGlbModelByName(name) {
  try {
    // Create a reference to the file you want to get the download URL for
    const storageRef = ref(storageBucket, `lucco/${name}.glb`);

    // Get the download URL for the file
    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  } catch (error) {
    console.error("Error getting download URL:", error);
    return null;
  }
}
