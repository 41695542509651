import React from "react";
import Meta from "./../components/Meta";
import TeamBiosSection2 from "./../components/TeamBiosSection2";
import TeamBiosSection from "./../components/TeamBiosSection";

function TeamPage(props) {
  return (
    <>
      <Meta title="Team" />
      <TeamBiosSection2
        title="Meet our amazing team"
        subtitle="They are working nonstop behind the scenes to help you build better products, web services and websites."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <TeamBiosSection
        title="Meet our talented team"
        subtitle="They are working nonstop behind the scenes to help you build better products, web services and websites."
        strapline="Real People"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default TeamPage;
