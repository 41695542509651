import React from "react";
import Meta from "./../components/Meta";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { CHECKOUT_PRODUCT, GET_PRODUCT_DETAILS } from "../apollo_queries/product-queries";

export default function ProductDetail() {
  const { productHandle } = useParams();

  const { loading, error, data } = useQuery(GET_PRODUCT_DETAILS, {
    variables: { handle: productHandle },
  });

  console.log("data",data);
  const product = data?.productByHandle;
  const image = product?.images.edges[0]

  const variantId = product?.variants.edges[0].node.id

  const [checkoutProduct, { data: checkoutData, loading: checkoutLoading, error: checkoutError }] = useMutation(CHECKOUT_PRODUCT,{
    variables: {variantId: variantId}
  });

  const handleCheckout = async() => {
    await checkoutProduct();
  };

  console.log(checkoutData, checkoutLoading, checkoutError )

  if (loading ) return <p>Loading...</p>;
  if (error ) return <p>Error: {error.message}</p>;

  return (
    <>
      <Meta title={product.title} />
      <div>
        <img
          style={{ width: 400, height: 400 }}
          src={image ? image.node.transformedSrc : "noUrl"}
          alt={image ? image.node.altText : "noAltText"}
          className="h-full w-full object-cover object-center group-hover:opacity-75"
        />
      </div>
      <h1>{product.title}</h1>
      <p>{product.description}</p>
      <p>{product.priceRange.minVariantPrice.currencyCode} ${product.priceRange.minVariantPrice.amount}</p>

      <button
        onClick={handleCheckout}
        style={{width: 200}}
        type="button"
        className="w-full bg-gray-900 border border-transparent rounded-md py-3 px-8 flex items-center
      justify-center text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2
      focus:ring-offset-gray-50 focus:ring-gray-500
      "
      >
        Pay {product.priceRange.minVariantPrice.currencyCode} ${product.priceRange.minVariantPrice.amount}
      </button>
    </>
  );
}
