import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Section from "./Section";
import TextField from "./TextField";
import Button from "./Button";
import { Link } from "./../util/router";
import newsletter from "./../util/newsletter";

function NewsletterSection(props) {
  const [subscribed, setSubscribed] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);
    // Parent component can optionally
    // find out when subscribed.
    props.onSubscribed && props.onSubscribed();
    // Subscribe them
    newsletter.subscribe({ email });
  };

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container py-4 md:py-0">
        <div className="relative" />
        <div className="pattern-dots text-blue-200 absolute top-0 right-0 w-32 h-32 lg:w-48 lg:h-48 transform translate-x-12 -translate-y-16" />
        <div className="pattern-dots text-blue-200 absolute bottom-0 left-0 w-32 h-32 lg:w-48 lg:h-48 transform -translate-x-12 translate-y-16" />
        <div className="grid grid-cols-1 md:grid-cols-2 bg-white rounded shadow overflow-hidden relative">
          <div
            className="bg-[image:var(--image)] bg-center bg-cover hidden md:block"
            style={{
              "--image": `url("${props.image}")`,
            }}
          />
          <div className="p-10 lg:py-12 lg:px-16">
            <div className="inline-flex items-center space-x-2 text-xl font-bold text-blue-700 mb-12">
              <svg
                className="opacity-75 hi-solid hi-code inline-block w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span>TechCode</span>
            </div>
            <h3 className="text-2xl sm:text-3xl font-extrabold mb-2">
              {props.title}
            </h3>
            <p className="prose prose-indigo mb-6">{props.subtitle}</p>

            {subscribed === true && (
              <div className="mt-3 text-center">You are now subscribed!</div>
            )}

            {subscribed === false && (
              <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex items-start space-x-3">
                  <TextField
                    type="email"
                    id="email"
                    placeholder="Your email"
                    error={errors.email}
                    {...register("email", {
                      required: "Please enter an email address",
                    })}
                  />
                  <Button type="submit" size="lg">
                    Subscribe
                  </Button>
                </div>
                <p className="text-sm text-gray-600 leading-6">
                  No spam ever, unsubscribe at any time.
                  <br />
                  Check out our{" "}
                  <Link
                    to="/legal/privacy-policy"
                    className="font-medium text-blue-600 hover:text-blue-400"
                  >
                    Privacy Policy
                  </Link>
                </p>
              </form>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default NewsletterSection;
