import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  uri: process.env.REACT_APP_SHOPIFY_URI, // Shopify GraphQL API URL
  headers: {
    'Accept': 'application/json',
    'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_SHOPIFY_TOKEN,
  },
  cache: new InMemoryCache(),
});

export default client;