import classNames from "classnames";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Modal = ({ children, onDone }) => {
  return (
    <div
      className={classNames(
        "",
        "fixed w-full h-full",
        "flex justify-center items-center overflow-scroll" ,
        "z-30 p-4  ",
      )}
    >
      <div
        className={classNames(
          "",
          "bg-black opacity-30",
          "w-screen h-screen min-h-fit z-20 fixed",
        )}
      />
      <div
        className={classNames(
          "",
          "w-full mt-24  max-w-lg rounded-xl p-6 backdrop-blur-2xl bg-white bg-opacity-80 z-30",
        )}
      >
        <button
            onClick={onDone}
          className={classNames(
            "",
            "rounded-full  bg-neutral-50 p-2",
            "hover:bg-neutral-100 hover:scale-[0.98]",
            "active:scale-[0.96]",
            "duration-200",
          )}
        >
          <XMarkIcon className="h-6 w-6 text-neutral-800" strokeWidth={2.0} />
        </button>
          {children}
      </div>
    </div>
  );
};

export default Modal;
