import classNames from "classnames";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

const AndroidARViewerButton = ({ URL, text }) => {
  return (
    <model-viewer
      className={"sr-only w-full h-fit z-40"}
      ar={true}
      camera-controls={true}
      touch-action="pan-y"
      src={URL}
      alt="Model"
      style={{ height: "64px", width: "100%" }}
    >
      <button
        slot="ar-button"
        className={classNames(
          "",
          "font-normal z-40",
          "bg-neutral-900 text-neutral-100",
          "px-3.5 py-3.5 rounded-xl",
          "w-full inline-flex justify-between items-center",
        )}
      >
        {text || "Görüntüleyin"}
        <ArrowTopRightOnSquareIcon
          className="h-5 w-5 text-white"
          strokeWidth={0.9}
        />
      </button>
    </model-viewer>
  );
};

export default AndroidARViewerButton;
