import classNames from "classnames";

const ModalHeader = ({ title, description }) => {
  return (
    <div className={classNames("", "flex flex-col gap-2 mt-4")}>
      <h2
        className={classNames(
          "",
          "max-w-md",
          "flex flex-col items-start justify-center",
          "text-3xl md:text-4xl font-medium tracking-tight",
        )}
      >
        {title}
      </h2>
      <h3
        className={classNames(
          "",
          "text-lg leading-6 md:text-xl max-w-md font-normal",
          "text-neutral-700 tracking-tight",
        )}
      >
        {description}
      </h3>
    </div>
  );
};

export default ModalHeader;
