import React from "react";
import Meta from "./../components/Meta";
import NewsletterSection2 from "./../components/NewsletterSection2";
import NewsletterSection from "./../components/NewsletterSection";
import NewsletterSection3 from "./../components/NewsletterSection3";

function NewsletterPage(props) {
  return (
    <>
      <Meta title="Newsletter" />
      <NewsletterSection2
        title="Subscribe to our newsletter"
        subtitle="Join us and receive the best curated news, freebies and resources directly to your inbox every week!"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <NewsletterSection
        title="Join us today"
        subtitle="Subscribe to our newsletter and receive the best curated news and resources directly to your inbox every week!"
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        image="https://images.unsplash.com/photo-1551434678-e076c223a692?crop=entropy&fit=crop&h=800&q=80&w=1200"
      />
      <NewsletterSection3
        title="Subscribe to our newsletter"
        subtitle="Join us and receive the best curated news, freebies and resources directly to your inbox every week!"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default NewsletterPage;
