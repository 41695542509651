import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import HeroSection2 from "./../components/HeroSection2";
import HeroSection4 from "./../components/HeroSection4";
import HeroSection3 from "./../components/HeroSection3";

function HeroPage(props) {
  return (
    <>
      <Meta title="Hero" />
      <HeroSection
        title="Premium leads for all your SaaS projects"
        subtitle="Focus on building your amazing service and we will do the rest. We grew more than 10,000 online businesses."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <HeroSection2
        title="Build web applications and websites with ease"
        subtitle="Carefully crafted, easy to customize, fully responsive UI components for your Tailwind CSS based projects."
        strapline=""
        size="lg"
        bgColor="bg-blue-900"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      />
      <HeroSection4
        title={
          <>
            We are <span className="font-light">TechCode</span>
          </>
        }
        subtitle="A passionate team who are here to help you code and build your business using powerful tools."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <HeroSection3
        title={
          <>
            We are <span className="font-light">TechCode</span>
          </>
        }
        subtitle="A passionate team who are here to help you code and build your business using powerful tools."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        leftImage="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?crop=entropy&fit=crop&h=800&w=1280"
        rightImage="https://images.unsplash.com/photo-1554232456-8727aae0cfa4?crop=entropy&fit=crop&h=800&q=80&w=600"
      />
    </>
  );
}

export default HeroPage;
