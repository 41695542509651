/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF } from "@react-three/drei";
import { useSnapshot } from "valtio";
import { state } from "../util/store";
import { useFrame } from "@react-three/fiber";
import { easing } from "maath";

export function LUCENTE(props) {
    const { nodes, materials } = useGLTF("../assets/models/3043_LUCENTE.glb");
    const snap = useSnapshot(state);

    useFrame((state, delta) =>
        easing.dampC(
            materials.Bright_White.color,
            snap?.material?.color,
            0.12,
            delta,
        ),
    );
    return (
        <group {...props} dispose={null}>
            <mesh
                name="3043_LUCENTE"
                castShadow={true}
                receiveShadow={true}
                geometry={nodes["3043_LUCENTE"].geometry}
                material={materials.Bright_White}
                material-roughness={snap?.material?.roughness}
                userData={{ name: "3043_LUCENTE" }}
            >
                <mesh
                    name="Sink_Cap004"
                    castShadow={true}
                    receiveShadow={true}
                    geometry={nodes.Sink_Cap004.geometry}
                    material={materials.Bright_White}
                    material-roughness={snap?.material?.roughness}
                    position={[0, 0.006, 0]}
                    userData={{ name: "Sink_Cap.004" }}
                />
            </mesh>
        </group>
    );
}

useGLTF.preload("../assets/models/3043_LUCENTE.glb");
