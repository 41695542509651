import React from "react";
import Meta from "./../components/Meta";
import ClientsSection2 from "./../components/ClientsSection2";
import ClientsSection from "./../components/ClientsSection";

function ClientsPage(props) {
  return (
    <>
      <Meta title="Clients" />
      <ClientsSection2
        title=""
        subtitle=""
        strapline="Trusted by these amazing companies"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <ClientsSection
        title="These companies trust us"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-blue-800"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      />
    </>
  );
}

export default ClientsPage;
