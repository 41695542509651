import classNames from "classnames";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { state } from "../util/store";
import React, { useState } from "react";
import { useSnapshot } from "valtio";

const ConfiguratorHeader = () => {
  const snap = useSnapshot(state);
  const [dropDownMenu, setDropDownMenu] = useState(false);

  return (
    <header
      className={classNames(
        "",
        "fixed top-0 z-10   items-center  w-full ",
        "flex flex-col p-4",
      )}
    >
      <div
        className={classNames(
          "bg-neutral-100 drop-shadow-2xl backdrop-blur-2xl h-16 w-full max-w-3xl ",
          "flex flex-row items-center rounded-xl overflow-hidden justify-between md:mt-0",
        )}
      >
        <div className={"pl-6"}>
          <img
            src="/assets/brand/logo.svg"
            alt="cover"
            className={"object-center opacity-90 object-cover h-3.5  z-10 "}
          />
        </div>

        <button
          onClick={() => setDropDownMenu(!dropDownMenu)}
          className={classNames(
            "",
            "bg-neutral-100 border-l-2 text-neutral-900 flex",
            "font-semibold tracking-tight ",
            "items-center justify-center min-w-fit px-8 h-full",
            "min-w-fit inline-flex items-center gap-2",
            "active:bg-neutral-200",
            "focus:bg-neutral-200",
          )}
        >
          Ürünler{" "}
          {dropDownMenu ? (
            <ChevronUpIcon className="h-6 w-6 " strokeWidth={2.0} />
          ) : (
            <ChevronDownIcon className="h-6 w-6 " strokeWidth={2.0} />
          )}
        </button>
      </div>
      {dropDownMenu && (
        <div
          className={classNames(
            "",
            "bg-neutral-100 drop-shadow-2xl backdrop-blur-2xl  w-full md:w-fit max-w-3xl mt-2 rounded-xl p-2.5",
          )}
        >
          <ul className={classNames("", "flex flex-row gap-2 overflow-auto")}>
            {snap.models.map((model) => (
              <li className={classNames("", "")}>
                <button
                  onClick={() => (state.model = model)}
                  className={classNames(
                    "",
                    "font-semibold tracking-tight",
                    " rounded-md px-4 py-3",
                    model.name === state.model.name
                      ? "bg-neutral-900 text-neutral-100"
                      : "bg-neutral-100 text-neutral-900",
                  )}
                >
                  {model.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
};
export default ConfiguratorHeader;
