import { Canvas, useFrame } from "@react-three/fiber";
import { useRouter } from "../../util/router";
import {
  AccumulativeShadows,
  ContactShadows,
  Environment,
  Lightformer,
  OrbitControls,
  RandomizedLight,
} from "@react-three/drei";
import { EffectComposer, SMAA } from "@react-three/postprocessing";
import { useSnapshot } from "valtio";
import { state } from "../util/store";

const ConfiguratorScene = ({ props, children }) => {

  const validConfigurator = {
    stand: StandModelLightPresets,
    monoblock: MonoBlockModelLightPresets,
  };

  // Get the component based on the router parameter or default to the first component
  const ActiveConfiguratorPresets = validConfigurator["stand"];
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <Canvas
      shadows
      dpr={[1, 1.5]}
      position={[0, 0.4, 0]}
      camera={{ position: [-0.8, 1.8, 1.8], fov: isMobile ? 32 : 20 }}
      gl={{ alpha: false, antialias: true, preserveDrawingBuffer: true }}
    >
      <ActiveConfiguratorPresets />
      <group position={isMobile ? [0, 0.14, 0] : [0, 0.05, 0]}>
        {/* Adjust the Y-coordinate (0.3) to move the model upwards */}

        {children}
      </group>
      <color attach="background" args={["#f3f3f3"]} />
      <Environment files="../assets/studio.hdr" resolution={512}>
        <Lightformer
          form="rect" // circle | ring | rect (optional, default = rect)
          intensity={1} // power level (optional = 1)
          color="white" // (optional = white)
          scale={[10, 5]} // Scale it any way you prefer (optional = [1, 1])
          target={isMobile ? [0, 0.14, 0] : [0, 0.05, 0]} // Target position (optional = undefined)
        />
      </Environment>
      <EffectComposer disableNormalPass multisampling={0}>
        <SMAA />
      </EffectComposer>
      <OrbitControls
        makeDefault
        autoRotate={true}
        autoRotateSpeed={0.5}
        minPolarAngle={Math.PI / 4}
        maxPolarAngle={Math.PI / 2.2}
        minDistance={1.8}
        maxDistance={2.8}
        rotateSpeed={0.4}
        enablePan={false}
        dampingFactor={0.1} // Adjust the damping factor for slower zooming
      />
    </Canvas>
  );
};

export function StandModelLightPresets({ props }) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const snap = useSnapshot(state);
  return (
    <group>
      <color attach="background" args={["#f3f3f3"]} />
      <pointLight
        position={[-0.65, 0.3, 0.3]}
        intensity={0.8} // Increase light intensity
        shadow-camera-far={5} // Adjust shadow camera far value
      />
      <ambientLight intensity={0.2} color="white" />
      <AccumulativeShadows
        opacity={0.95}
        scale={2}
        blur={0.44}
        far={1}
        position={isMobile ? [0, 0.14, 0] : [0, 0.05, 0]}
        resolution={512}
        color={snap.material.color}
        intensity={2}
        bias={0.001}
        castShadow={true}
        radius={1}
      >
        <RandomizedLight
          castShadow
          amount={12}
          frames={100}
          position={[-0.65, 0.3, 0.3]}
        />
      </AccumulativeShadows>
    </group>
  );
}

export function MonoBlockModelLightPresets({ props }) {
  return (
    <group>
      <color attach="background" args={["#f3f3f3"]} />
      <pointLight
        position={[-0.65, 0.4, 0.3]}
        intensity={0.8} // Increase light intensity
        shadow-camera-far={5} // Adjust shadow camera far value
      />
      <ambientLight intensity={0} color="white" />
    </group>
  );
}

export default ConfiguratorScene;
