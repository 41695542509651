import React from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { easing } from "maath";
import { useSnapshot } from "valtio";
import { state } from "../util/store";

export function DECENTE(props) {
  const snap = useSnapshot(state);
  const { nodes, materials } = useGLTF("../assets/models/3040_DECENTE.glb");

  useFrame((state, delta) =>
    easing.dampC(
      materials.Bright_White.color,
      snap?.material?.color,
      0.12,
      delta,
    ),
  );

  return (
    <group {...props} dispose={null}>
      <mesh
        visible={true}
        name="3040_DECENTE"
        dispose={null}
        castShadow={true}
        receiveShadow={true}
        geometry={nodes["3040_DECENTE"].geometry}
        material={materials.Bright_White}
        material-roughness={snap?.material?.roughness}
        userData={{ name: "3040_DECENTE" }}
      >
        <mesh
          visible={true}
          name="Sink_Cap001"
          dispose={null}
          castShadow={true}
          receiveShadow={true}
          geometry={nodes.Sink_Cap001.geometry}
          material={materials.Bright_White}
          material-roughness={snap?.material?.roughness}
          position={[0, 0.014, 0]}
          userData={{ name: "Sink_Cap.001" }}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("../assets/models/3040_DECENTE.glb");
