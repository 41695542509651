import React from "react";
import "./../styles/global.css";
import NavbarPage from "./navbar";
import NavbarLandingPage from "./navbar-landing";
import HeroPage from "./hero";
import CallToActionPage from "./call-to-action";
import FaqPage from "./faq";
import NewsletterPage from "./newsletter";
import FeaturesPage from "./features";
import ClientsPage from "./clients";
import ContentPage from "./content";
import TestimonialsPage from "./testimonials";
import TeamPage from "./team";
import ProductList from "./product-list";
import StatsPage from "./stats";
import PricingPage from "./pricing";
import PricingLandingPage from "./pricing-landing";
import ContactPage from "./contact";
import FooterPage from "./footer";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import DashboardPage from "./dashboard";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import { Router, Routes, Route } from "./../util/router";
import NotFoundPage from "./404";
import { Analytics } from "../util/analytics";
import { Chat } from "../util/chat";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import IndexPage from "./index";
import Nav from "./../Nav.js";

import ConfiguratorPage from "../configurators/configurator";
import ProductDetail from "../components/ProductDetail";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <Analytics />
          <Chat />
          <Nav />
          <Routes>
            <Route path="/" element={<IndexPage />} />

            <Route path="/configurator/:section" element={<ConfiguratorPage />} />

            <Route path="/navbar" element={<NavbarPage />} />

            <Route path="/navbar-landing" element={<NavbarLandingPage />} />

            <Route path="/hero" element={<HeroPage />} />

            <Route path="/call-to-action" element={<CallToActionPage />} />

            <Route path="/faq" element={<FaqPage />} />

            <Route path="/newsletter" element={<NewsletterPage />} />

            <Route path="/features" element={<FeaturesPage />} />

            <Route path="/clients" element={<ClientsPage />} />

            <Route path="/content" element={<ContentPage />} />

            <Route path="/testimonials" element={<TestimonialsPage />} />

            <Route path="/team" element={<TeamPage />} />

            <Route path="/product-list" element={<ProductList />} />
            
            <Route path="/products/:productHandle" element={<ProductDetail />} />

            <Route path="/stats" element={<StatsPage />} />

            <Route path="/pricing" element={<PricingPage />} />

            <Route path="/pricing-landing" element={<PricingLandingPage />} />

            <Route path="/contact" element={<ContactPage />} />

            <Route path="/footer" element={<FooterPage />} />

            <Route path="/auth/:type" element={<AuthPage />} />

            <Route path="/settings/:section" element={<SettingsPage />} />

            <Route path="/legal/:section" element={<LegalPage />} />

            <Route path="/dashboard" element={<DashboardPage />} />

            <Route path="/purchase/:plan" element={<PurchasePage />} />

            <Route path="/firebase-action" element={<FirebaseActionPage />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
