import React from "react";
import Meta from "./../components/Meta";
import CtaSection from "./../components/CtaSection";
import CtaSection3 from "./../components/CtaSection3";
import CtaSection2 from "./../components/CtaSection2";

function CallToActionPage(props) {
  return (
    <>
      <Meta title="Call To Action" />
      <CtaSection
        title="Create an account today!"
        subtitle="Inspiring results from day one without the pain. Get your own custom dashboard and start building amazing services."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection3
        title="Create an account today!"
        subtitle="Inspiring results from day one without the pain. Get your own custom dashboard and start building amazing services."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection2
        title="Ready? Let’s do it!"
        subtitle="Get your own custom dashboard and start building amazing services, always with the most solid and rock steady foundation."
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      />
    </>
  );
}

export default CallToActionPage;
