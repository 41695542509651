import React from "react";
import Meta from "./../components/Meta";
import StatsSection2 from "./../components/StatsSection2";
import StatsSection from "./../components/StatsSection";
import StatsSection3 from "./../components/StatsSection3";

function StatsPage(props) {
  return (
    <>
      <Meta title="Stats" />
      <StatsSection2
        title="Trusted by thousands of professionals"
        subtitle="Web developers from all over the world are using our products. Join them and build something amazing!"
        strapline="Inspiring Results"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <StatsSection
        title="Trusted by thousands of professionals"
        subtitle="Web developers from all over the world are using our products. Join them and build something amazing!"
        strapline="Inspiring Results"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <StatsSection3
        title="Trusted by thousands of professionals"
        subtitle="Web developers from all over the world are using our products. Join them and build something amazing!"
        strapline="Inspiring Results"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default StatsPage;
